<!DOCTYPE html>
<html lang="de">
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content="" />
  <meta name="author" content="" />
</head>
<body id="page-top">
<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
  <div class="container">
<!--    <a class="navbar-brand js-scroll-trigger" href="#page-top"><img src="assets/img/navbar-logo.svg" alt="" /></a>-->
    <a class="navbar-brand js-scroll-trigger brandname" [ngxScrollTo]="'#page-top'" href="#page-top">READY-IV</a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      Menu
      <i class="fas fa-bars ml-1"></i>
    </button>
    <!--    NAVIGATION BAR   -->
    <div class="collapse navbar-collapse" id="navbarResponsive" gaCategory="navigationbar">
      <ul class="navbar-nav text-uppercase ml-auto">
        <li gaEvent gaAction="click motivation" [ngxScrollTo]="'#motivation'" class="nav-item">
          <a class="nav-link" href="#motivation">{{ 'navbar.our-motivation' | translate }}</a>
        </li>
        <li gaEvent gaAction="click team" [ngxScrollTo]="'#team'" class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#team">{{ 'navbar.team' | translate }}</a>
        </li>
        <li gaEvent gaAction="click services" [ngxScrollTo]="'#services'" class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#services">{{ 'navbar.services' | translate }}</a>
        </li>
<!--        <li gaEvent gaAction="click news" class="nav-item"><a class="nav-link js-scroll-trigger" href="#news">{{ 'navbar.news' | translate }}</a></li>-->
        <li gaEvent gaAction="click contact" [ngxScrollTo]="'#contact'" class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#contact">{{ 'navbar.appointment' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Masthead-->
<header class="masthead">
  <div class="container">
    <div class="masthead-subheading">{{ 'intro.text' | translate }}</div>
    <div class="masthead-heading text-uppercase">{{ 'intro.title' | translate }}</div>
    <a gaCategory="CTA" gaEvent="click tellmore" [ngxScrollTo]="'#motivation'" class="btn btn-primary btn-xl text-uppercase js-scroll-trigger">{{ 'intro.button-title' | translate }}</a>
  </div>
</header>
<!-- Motivation-->
<section class="page-section" id="motivation" data-scroll data-scroll-class="visible" data-scroll-position="top">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">{{ 'motivation.title' | translate }}</h2>
      <h3 class="section-subheading text-muted">{{ 'motivation.subtitle' | translate }}</h3>
    </div>
    <ul class="timeline">
      <li>
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="../../assets/img/motivation/1.jpg" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="subheading">{{ 'motivation.who-we-are.title' | translate }}</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">{{ 'motivation.who-we-are.body' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="../../assets/img/motivation/2.jpg" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="subheading">{{ 'motivation.why-we-do.title' | translate }}</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">{{ 'motivation.why-we-do.body' | translate }}</p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="../../assets/img/motivation/3.jpg" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="subheading">{{ 'motivation.what-we-stand-for.title' | translate }}</h4>
          </div>
          <div class="timeline-body">
            <p class="text-muted">{{ 'motivation.what-we-stand-for.body' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image"><img class="rounded-circle img-fluid" src="../../assets/img/motivation/4.jpg" alt="" /></div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="subheading">{{ 'motivation.where-you-can-find.title' | translate }}</h4>
          </div>
          <div class="timeline-body">{{ 'motivation.where-you-can-find.body' | translate }}</div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-image" style="white-space: pre-wrap">
          <h4>
            {{ 'motivation.be-a-part.text' | translate }}
          </h4>
        </div>
      </li>
    </ul>
  </div>
</section>
<!-- Team-->
<section class="page-section bg-light" id="team">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">{{ 'team.title' | translate }}</h2>
      <h3 class="section-subheading text-muted">{{ 'team.subtitle' | translate }}</h3>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="team-member" gaCategory="social-media-seb">
          <img class="mx-auto rounded-circle" src="../../assets/img/team/2.png" alt="" />
          <h4>Sebastian Paul</h4>
          <p class="text-muted">CEO & Founder</p>
          <a gaEvent gaAction="click linkedin" class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/sebastian-paul-9493909a/"><fa-icon [icon]="faLinkedin"></fa-icon></a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="team-member" gaCategory="social-media-ben">
          <img class="mx-auto rounded-circle" src="../../assets/img/team/3.jpg" alt="" />
          <h4>Benjamin Karla</h4>
          <p class="text-muted">CEO & Founder</p>
          <a gaEvent gaAction="click xing" class="btn btn-dark btn-social mx-2" href="https://www.xing.com/profile/Benjamin_Karla/cv"><fa-icon [icon]="faXing"></fa-icon></a>
          <a gaEvent gaAction="click linkedin" class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/benjamin-karla-a2737834/"><fa-icon [icon]="faLinkedin"></fa-icon></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">{{ 'team.bottom-text' | translate }}</p></div>
    </div>
  </div>
</section>
<!-- Services-->
<section class="page-section" id="services">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">{{ 'services.title' | translate }}</h2>
      <h3 class="section-subheading text-muted">{{ 'services.subtitle' | translate }}</h3>
    </div>
    <div class="row text-center">
      <div class="col-md-4" style="">
        <span class="fa-stack fa-4x">
          <div class="service-circle" style="background-color: #af6eff;">
            <a><fa-icon class="service-icon" [icon]="faTint"></fa-icon></a>
          </div>
        </span>
        <h4 class="my-3" style="top:40px;">{{ 'services.hydration-boost-iv.title' | translate }}</h4>
        <p class="text-muted" style="text-align: justify;">{{ 'services.hydration-boost-iv.body' | translate }}</p>
      </div>
      <div class="col-md-4">
        <span class="fa-stack fa-4x">
          <div class="service-circle" style="background-color: #ffd500; text-align: center; top: calc(50% - 10px);">
            <fa-icon class="service-icon" [icon]="faBolt"></fa-icon>
          </div>
        </span>
        <h4 class="my-3">{{ 'services.liquid-energy-iv.title' | translate }}</h4>
        <p class="text-muted" style="text-align: justify;">{{ 'services.liquid-energy-iv.body' | translate }}</p>
      </div>
      <div class="col-md-4">
        <span class="fa-stack fa-4x">
          <div class="service-circle" style="background-color: #9eebf7; text-align: center;">
            <fa-icon class="service-icon" [icon]="faChild"></fa-icon>
          </div>
        </span>
        <h4 class="my-3">{{ 'services.ultimate-recovery-iv.title' | translate }}</h4>
        <p class="text-muted" style="text-align: justify;">{{ 'services.ultimate-recovery-iv.body' | translate }}</p>
      </div>
    </div>
  </div>
</section>
<!-- News -->
<!--<section class="page-section bg-light" id="news">-->
<!--  <div id="instafeed"></div>-->
<!--  <div class="container">-->
<!--    <div class="text-center">-->
<!--      <h2 class="section-heading text-uppercase">Portfolio</h2>-->
<!--      <h3 class="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="col-lg-4 col-sm-6 mb-4">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal1">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/01-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Threads</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Illustration</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-sm-6 mb-4">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal2">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/02-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Explore</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Graphic Design</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-sm-6 mb-4">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal3">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/03-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Finish</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Identity</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-sm-6 mb-4 mb-lg-0">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal4">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/04-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Lines</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Branding</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-sm-6 mb-4 mb-sm-0">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal5">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/05-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Southwest</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Website Design</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 col-sm-6">-->
<!--        <div class="portfolio-item">-->
<!--          <a class="portfolio-link" data-toggle="modal" href="#portfolioModal6">-->
<!--            <div class="portfolio-hover">-->
<!--              <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>-->
<!--            </div>-->
<!--            <img class="img-fluid" src="assets/img/portfolio/06-thumbnail.jpg" alt="" />-->
<!--          </a>-->
<!--          <div class="portfolio-caption">-->
<!--            <div class="portfolio-caption-heading">Window</div>-->
<!--            <div class="portfolio-caption-subheading text-muted">Photography</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
<!--&lt;!&ndash; Clients&ndash;&gt;-->
<!--<div class="py-5">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-md-3 col-sm-6 my-3">-->
<!--        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/envato.jpg" alt="" /></a>-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 my-3">-->
<!--        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/designmodo.jpg" alt="" /></a>-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 my-3">-->
<!--        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/themeforest.jpg" alt="" /></a>-->
<!--      </div>-->
<!--      <div class="col-md-3 col-sm-6 my-3">-->
<!--        <a href="#!"><img class="img-fluid d-block mx-auto" src="assets/img/logos/creative-market.jpg" alt="" /></a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!-- Contact-->
<section class="page-section" id="contact">
  <div class="container">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">{{ 'contact.title' | translate }}</h2>
      <h3 class="section-subheading text-muted">{{ 'contact.subtitle' | translate }}</h3>
    </div>
    <form id="contactForm" name="sentMessage" novalidate="novalidate">
      <div class="row align-items-stretch mb-5">
        <div class="col-md-6">
          <div class="form-group">
            <input class="form-control" id="name" type="text" placeholder="{{ 'contact.form.input-name' | translate }}"
                   required="required" data-validation-required-message="Please enter your name." />
            <p class="help-block text-danger"></p>
          </div>
          <div class="form-group">
            <input class="form-control" id="email" type="email" placeholder="{{ 'contact.form.input-mail' | translate }}"
                   required="required" data-validation-required-message="Please enter your email address." />
            <p class="help-block text-danger"></p>
          </div>
          <div class="form-group mb-md-0">
            <input class="form-control" id="phone" type="tel" placeholder="{{ 'contact.form.input-phone' | translate }}"
                   required="required" data-validation-required-message="Please enter your phone number." />
            <p class="help-block text-danger"></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group form-group-textarea mb-md-0">
            <textarea class="form-control" id="message" placeholder="{{ 'contact.form.input-message' | translate }}" required="required" data-validation-required-message="Please enter a message."></textarea>
            <p class="help-block text-danger"></p>
          </div>
        </div>
      </div>
      <div class="text-center">
        <div id="success"></div>
        <button gaEvent="submit" gaCategory="contact form" class="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit">{{ 'contact.form.submit-button' | translate }}</button>
      </div>
    </form>
  </div>
</section>
<!-- Footer-->
<footer class="footer py-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 text-lg-left" gaCategory="footer translation">Copyright © READY-IV 2021
        <a gaEvent gaAction="click german"><img class="translationflag" (click)="useLanguage('de')" src="assets/img/i18n/german.png"/></a>
        <a gaEvent gaAction="click english"><img class="translationflag" (click)="useLanguage('en')" src="assets/img/i18n/english.png"/></a>
      </div>
      <div class="col-lg-4 my-3 my-lg-0" gaCategory="footer social-media">
        <a gaEvent gaAction="click instagram" class="btn btn-dark btn-social mx-2" href="https://instagram.com/readyiv2020?igshid=1a54mphj58et9" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
        <a gaEvent gaAction="click twitter" class="btn btn-dark btn-social mx-2" href="https://twitter.com/iv_ready" target="_blank"><fa-icon [icon]="faTwitter"></fa-icon></a>
        <a gaEvent gaAction="click facebook" class="btn btn-dark btn-social mx-2" href="https://www.facebook.com/readyiv/" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a>
        <a gaEvent gaAction="click xing" class="btn btn-dark btn-social mx-2" href="https://www.xing.com/companies/ready-iv" target="_blank"><fa-icon [icon]="faXing"></fa-icon></a>
        <a gaEvent gaAction="click linkedin" class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/ready-iv" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
      </div>
      <div class="col-lg-4 text-lg-right" gaCategory="footer">
        <a gaEvent gaAction="click privacy policy" href="#!">{{ 'footer.data-privacy' | translate }}</a>
        <a> | </a>
        <a gaEvent gaAction="click termins of use" href="#!">{{ 'footer.terms-of-use' | translate }}</a>
        <a> | </a>
        <a gaEvent gaAction="click imprint" class="portfolio-link" href="#impressumModal" data-toggle="modal">{{ 'footer.imprint' | translate }}</a>
        <a> | </a>
        <a gaEvent gaAction="click faq" href="#faqModal" data-toggle="modal">{{ 'footer.faq' | translate }}</a>
        <a> | </a>
        <a gaEvent gaAction="click documents" href="#documentModal" data-toggle="modal">{{ 'footer.documents' | translate }}</a>
        <a> | </a>
        <a gaEvent gaAction="click jobs" href="#jobsModal" data-toggle="modal">{{ 'footer.jobs' | translate }}</a>
      </div>
    </div>
  </div>
</footer>
<!-- Modals-->
<!-- Impressum Modal -->
<div class="portfolio-modal modal fade" id="impressumModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="modal-body">
              <app-imprint></app-imprint>
              <button class="btn btn-primary" data-dismiss="modal" type="button">
                <fa-icon [icon]="faTimes"></fa-icon>
                <a class="text-uppercase"> {{ 'common.button-close' | translate }}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Document Modal-->
<div class="portfolio-modal modal fade" id="documentModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="modal-body">
              <!-- Project Details Go Here-->
              <h2 class="text-uppercase">{{ 'modal-documents.title' | translate }}</h2>
              <p class="item-intro text-muted">{{ 'modal-documents.subtitle' | translate }}</p>

              <ul class="list-inline" style="text-align: left">
                <li>
                  <fa-icon [icon]="faFileMedical" class="document-icon"></fa-icon>
                  <a class="document-title" href="https://forms.gle/4JuCVH5QQwQpcqDr7">{{ 'modal-documents.questionaire-before' | translate }}</a>
                </li>
                <li>
                  <fa-icon [icon]="faFileMedical" class="document-icon"></fa-icon>
                  <a class="document-title" href="https://forms.gle/2brqNcncEd2effms7">{{ 'modal-documents.questionaire-after' | translate }}</a>
                </li>
                <li>
                  <fa-icon [icon]="faFileMedical" class="document-icon"></fa-icon>
                  <a class="document-title" href="https://forms.gle/eygjjPpUd1sYk9xT7">{{ 'modal-documents.questionaire-customer-satisfaction' | translate }}</a>
                </li>
              </ul>
              <button class="btn btn-primary" data-dismiss="modal" type="button">
                <fa-icon [icon]="faTimes"></fa-icon>
                <a class="text-uppercase"> {{ 'common.button-close' | translate }}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FAQ Modal-->
<div class="portfolio-modal modal fade" id="faqModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="modal-body">
              <h2 class="text-uppercase">FAQ</h2>
              <!-- FAQ Text -->
              <div style="margin-top: 50px" *ngFor="let entry of faq" >
                <h3 class="text-uppercase" style="text-align: left">{{ 'faq.' + entry + '.title' | translate }}</h3>
                <p class="text-muted" style="text-align: left">{{ 'faq.' + entry + '.answer' | translate }}</p>
              </div>
              <!-- Button -->
              <button class="btn btn-primary" data-dismiss="modal" type="button">
                <fa-icon [icon]="faTimes"></fa-icon>
                <a class="text-uppercase"> {{ 'common.button-close' | translate }}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- jobs Modal-->
<div class="portfolio-modal modal fade" id="jobsModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="close-modal" data-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="modal-body"><h2 class="text-uppercase">{{ 'modal-jobs.title' | translate }}</h2>
              <!-- Jobs Text -->
              <p class="item-intro text-muted">{{ 'modal-jobs.subtitle' | translate }}</p>
              <p class="item-intro text-muted">{{ 'modal-jobs.intro' | translate }}</p>
              <h3 class="item-intro text-muted">{{ 'modal-jobs.conditions-headline' | translate }}</h3>
              <p class="item-intro text-muted">{{ 'modal-jobs.conditions-body' | translate }}</p>
              <h3 class="item-intro text-muted" style="margin-bottom: 30px">{{ 'modal-jobs.todo-headline' | translate }}</h3>
              <ul class="text-muted" style="text-align: left;" *ngFor="let entry of todos">
                <li>
                  <a>{{ 'modal-jobs.todos.' + entry | translate }}</a>
                </li>
              </ul>
              <h3 class="item-intro text-muted" style="margin-bottom: 30px">{{ 'modal-jobs.we-offer-headline' | translate }}</h3>
              <ul class="text-muted" style="text-align: left;" *ngFor="let entry of offers">
                <li>
                  <a>{{ 'modal-jobs.offers.' + entry | translate }}</a>
                </li>
              </ul>
              <p></p>
              <p></p>
              <p class="item-intro text-muted">{{ 'modal-jobs.lastTextBlock' | translate }}</p>
              <!-- Button -->
              <button class="btn btn-primary" data-dismiss="modal" type="button">
                <fa-icon [icon]="faTimes"></fa-icon>
                <a class="text-uppercase"> {{ 'common.button-close' | translate }}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- ************* LIBRARYS  ************* -->
<!-- Bootstrap core JS-->
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
<!-- Third party plugin JS-->
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>
<!-- Contact form JS-->
<script src="assets/mail/jqBootstrapValidation.js"></script>
<script src="assets/mail/contact_me.js"></script>
<!-- Core theme JS-->
<script src="assets/js/scripts.js"></script>
<!-- Google Analytics -->
<script>
  window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
</script>
<script async src='https://www.google-analytics.com/analytics.js'></script>
<!-- End Google Analytics -->
<script src="../dist/scroll-triggers.js"></script>
<script>
  ScrollTriggers([{
    el: document.querySelectorAll('.scroll-js'),
    className: 'visible',
    position: 'top',
    inView: function(el, options) {
      console.log('in view', el, options);
    },
    outOfView: function(el, options) {
      console.log('out of view', el, options);
    }
  }]);
  window.addEventListener(ScrollTriggers.Events.In, console.log);
  window.addEventListener(ScrollTriggers.Events.Out, console.log);
</script>
</body>
</html>


<!--<app-navigationbar></app-navigationbar>-->
<!--<router-outlet></router-outlet>-->
<!--<app-comingsoon></app-comingsoon>-->
<!--<app-footer></app-footer>-->
