import { Component, OnInit } from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.css']
})
export class ComingsoonComponent implements OnInit {

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit() {
    this.gaService.pageView('/comingsoon', 'Page View Comingsoon');
  }

}
