import {ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  action?: string;
  label: string;
}

@Injectable()
export class AnalyticsService {
  gaService: GoogleAnalyticsService;
  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();

  constructor() {}

  public startTracking(): void {
    this.subscribeToEvents();
  }

  private subscribeToEvents(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'PAGEVIEW') {
        this.gaService.pageView('/startpage', 'Pageview Startpage', e.label);
      } else if (e.type === 'EVENT') {
        this.gaService.event(e.action, e.category, e.label);
      }
    });
  }

  public trackVirtualPageview(name: string): void {
    this.eventsQueue$.next({type: 'PAGEVIEW', label: name});
  }

  public trackEvent(category: string, action: string, label: string) {
    this.eventsQueue$.next({type: 'EVENT', category, action, label});
  }
}
