import {Component, OnDestroy, OnInit} from '@angular/core';
import { faChild, faTint, faBolt, faTimes, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faLinkedin, faXing} from '@fortawesome/free-brands-svg-icons';
import {AnalyticsService} from '../common/services/google-analytics-service';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {scrollTriggers} from 'scroll-triggers';
@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.css']
})

export class StartpageComponent implements OnInit, OnDestroy {
  constructor(private translate: TranslateService, private analyticsService: AnalyticsService, private ccService: NgcCookieConsentService, private translateService: TranslateService) {}
  faChild = faChild;
  faTint = faTint;
  faBolt = faBolt;
  faTimes = faTimes;
  faFileMedical = faFileMedical;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faXing = faXing;

  // keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  // Zur Hilfe bei der Übersetzung der FAQ TODO: DIe Arrays entfernen und das ngFor optimaler einsetzen
  faq = ['question1', 'question2', 'question3'];
  todos = ['1', '2', '3', '4', '5', '6'];
  offers = ['1', '2', '3', '4', '5', '6'];

  ngOnInit(): void {
    // *************** COOKIE CONSENT ******************
    // TODO: Das darf erst ausgeführt werden, wenn der Benutzer sein Einverständnis gibt
    this.analyticsService.startTracking(); // set cookie and start collecting the history

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen$', this.ccService.getConfig());
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen$', this.ccService.getConfig());
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('initialize$', this.ccService.getConfig(), status);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('statusChange$', this.ccService.getConfig());
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('revokeChoice$', this.ccService.getConfig());
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('noCookieLaw$', this.ccService.getConfig());
      });
    // *************** LANGUAGE SETTINGS ******************
    // Support for translated cookies messages
    this.translateService.addLangs(['de', 'en']);
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|de/) ? browserLang : 'de');

    this.translateService//
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }



  useLanguage(language: any) {
    this.translate.use(language);
  }
}
