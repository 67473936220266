import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from './common/navbar/navbar.component';
import { ImprintComponent } from './common/imprint/imprint.component';
import { StartpageComponent } from './startpage/startpage.component';
import { FooterComponent } from './common/footer/footer.component';
import { AnalyticsService } from './common/services/google-analytics-service';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookie.domain // (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#eaf7f7',
      text: '#5c7291'
    },
    button: {
      background: 'transparent',
      text: '#56cbdb',
      border: '#56cbdb'
    }
  },
  position: 'bottom',
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ComingsoonComponent,
    NavbarComponent,
    ImprintComponent,
    StartpageComponent,
    FooterComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        HttpClientModule,
        TranslateModule.forRoot({
            // defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxGoogleAnalyticsModule.forRoot(environment.googleanalyticstrackingcode),
        ScrollToModule.forRoot(),
        NgxGoogleAnalyticsRouterModule,
        ScrollToModule
    ],
  providers: [AnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
